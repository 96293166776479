export default {
  data: () => ({
    datePicker: false,
    date: ''
  }),
  methods: {
    open(date = '') {
      this.date = date;
      console.warn(this.date);
      this.datePicker = true;
    },
    datePicked() {
      this.$emit('datePicked', this.date);
    }
  }
};