import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "800",
      "persistent": ""
    },
    model: {
      value: _vm.taskObjects,
      callback: function ($$v) {
        _vm.taskObjects = $$v;
      },
      expression: "taskObjects"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "border-bottom-primary-4",
    attrs: {
      "top": "",
      "flat": ""
    }
  }, [_c(VToolbarTitle, {
    staticClass: "flex text-center primary--text"
  }, [_vm._v(" Verlinkte Elemente ")])], 1)], 1), _c(VCardText, [_c('div', [_c(VRow, {
    staticClass: "mt-2",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-overline font-weight-bold"
  }, [_vm._v("Adressen")]), _c(VDivider)], 1), _c(VCol, {
    staticClass: "px-3",
    attrs: {
      "cols": "12"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.selectableAddresses,
      "search-input": _vm.addressSearch,
      "return-object": "",
      "item-text": "displayName",
      "no-data-text": "Keine Adressen gefunden",
      "placeholder": "Hinzufügende Adressen",
      "dense": "",
      "outlined": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.addressSearch = $event;
      },
      "update:search-input": function ($event) {
        _vm.addressSearch = $event;
      },
      "input": _vm.addAddress
    }
  })], 1), _c(VCol, {
    staticClass: "px-3",
    attrs: {
      "cols": "12"
    }
  }, [_c(VCard, {
    attrs: {
      "outlined": ""
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.addressHeaders,
      "items": _vm.selectedAddresses,
      "item-class": _vm.isSelectedAddress
    },
    on: {
      "click:row": _vm.selectAddress
    },
    scopedSlots: _vm._u([{
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_c(VBtn, {
          staticClass: "rounded-sm",
          attrs: {
            "icon": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.openAddressInAnotherWindow(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-open-in-new")])], 1), _c(VBtn, {
          staticClass: "rounded-sm",
          attrs: {
            "icon": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.removeAddress(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-delete")])], 1)];
      }
    }])
  })], 1)], 1)], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-overline font-weight-bold"
  }, [_vm._v("Dokumente " + _vm._s(_vm.selectedAddress ? `von ${_vm.selectedAddress.displayName}` : ''))]), _c(VDivider)], 1), _c(VCol, {
    staticClass: "px-3",
    attrs: {
      "cols": "12"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "search-input": _vm.documentSearch,
      "items": _vm.selectableDocuments,
      "return-object": "",
      "no-filter": "",
      "item-text": "displayName",
      "no-data-text": "Keine Dokumente gefunden",
      "placeholder": "Hinzufügende Dokumente",
      "dense": "",
      "outlined": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.documentSearch = $event;
      },
      "update:search-input": function ($event) {
        _vm.documentSearch = $event;
      },
      "input": _vm.addDocument
    }
  })], 1), _c(VCol, {
    staticClass: "px-3",
    attrs: {
      "cols": "12"
    }
  }, [_c(VCard, {
    attrs: {
      "outlined": ""
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.documentHeaders,
      "items": _vm.selectedDocuments
    },
    scopedSlots: _vm._u([{
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_c(VBtn, {
          staticClass: "rounded-sm",
          attrs: {
            "icon": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.openDocumentInAnotherWindow(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-open-in-new")])], 1), _c(VBtn, {
          staticClass: "rounded-sm",
          attrs: {
            "icon": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.removeDocument(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-delete")])], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1)]), _c(VCardActions, [_c(VBtn, {
    attrs: {
      "color": "error",
      "outlined": "",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.taskObjects = false;
      }
    }
  }, [_vm._v("Abbrechen")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "outlined": "",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.taskObjects = false;
        _vm.save();
      }
    }
  }, [_vm._v(" Speichern ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };