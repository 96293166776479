import "core-js/modules/es.array.push.js";
import axios from 'axios';
export default {
  data: () => ({
    taskObjects: false,
    addressSearch: '',
    selectableAddresses: [],
    selectedAddress: null,
    selectedAddresses: [],
    documentSearch: '',
    selectableDocuments: [],
    selectedDocuments: [],
    addressHeaders: [{
      text: 'Name',
      value: 'displayName'
    }, {
      text: 'Ort',
      value: 'additionalData.location'
    }, {
      text: 'Aktionen',
      value: 'actions',
      sortable: false,
      align: 'end'
    }],
    documentHeaders: [{
      text: 'Nr.',
      value: 'displayName'
    }, {
      text: 'Typ',
      value: 'additionalData.documentType'
    }, {
      text: 'Adresse',
      value: 'additionalData.addressName'
    }, {
      text: 'Aktionen',
      value: 'actions',
      sortable: false,
      align: 'end'
    }]
  }),
  methods: {
    open(objects = []) {
      this.addressSearch = '';
      this.documentSearch = '';
      this.selectedAddress = null;
      this.taskObjects = true;
      if (objects.length != 0) {
        this.selectedAddresses = [];
        this.selectedDocuments = [];
      }
      objects.forEach(object => {
        if (object.type == 'DOCUMENT') {
          this.selectedDocuments.push(object);
        } else if (object.type == 'ADDRESS') {
          this.selectedAddresses.push(object);
        }
      });
    },
    twoCharactersValidation(value) {
      return this.filtersActive || !!value && String(value).length > 2 || 'Bitte geben Sie mehr als zwei Zeichen ein';
    },
    searchTrigger() {
      if (this.addressSearch != null && this.addressSearch != '' && String(this.addressSearch).length > 2) {
        axios.get('v1/crm/addresses/search/fullText', {
          params: {
            q: this.addressSearch
          }
        }).then(response => {
          this.selectableAddresses = [];
          response.data.forEach(address => {
            this.selectableAddresses.push({
              type: 'ADDRESS',
              displayName: address.name,
              parentWfCid: null,
              additionalData: {
                location: address.street + ', ' + address.postcode + ' ' + address.city,
                lfdnr: address.lfdnr
              }
            });
          });
        });
      }
    },
    addAddress(adr) {
      if (this.selectedAddresses.filter(address => {
        return address.additionalData.lfdnr === adr.additionalData.lfdnr;
      }).length == 0) {
        this.selectedAddresses.push(adr);
        this.selectAddress(adr);
      }
      this.addressSearch = '';
    },
    selectAddress(adr) {
      this.selectableDocuments = [];
      this.selectedAddress = adr;
      axios.get(`v1/crm/addresses/${Number.parseInt(adr.additionalData.lfdnr)}/documents`).then(response => {
        response.data.forEach(document => {
          this.selectableDocuments.push({
            type: 'DOCUMENT',
            displayName: document.shortType + ' ' + document.nr,
            additionalData: {
              documentType: document.type,
              addressName: document.addressKdSortname
            },
            wfCid: document.wf_cid,
            adrReference: this.selectedAddress
          });
        });
      });
    },
    removeAddress(address) {
      this.selectedDocuments = this.selectedDocuments.filter(document => {
        if (document.adrReference) {
          return document.adrReference.additionalData.lfdnr !== address.additionalData.lfdnr;
        } else if (document.parentWfCid) {
          return document.parentWfCid !== address.wfCid;
        }
      });
      this.selectedAddresses.splice(this.selectedAddresses.indexOf(address), 1);
      if (this.selectedAddress == address) this.selectedAddress = null;
    },
    openAddressInAnotherWindow(address) {
      const routeData = this.$router.resolve({
        name: 'crmAddress',
        params: {
          lfdnr: address.additionalData.lfdnr
        }
      });
      window.open(routeData.href, '_blank');
    },
    addDocument(document) {
      if (!this.selectedDocuments.includes(document)) {
        this.selectedDocuments.push(document);
        this.documentSearch = '';
      }
    },
    removeDocument(document) {
      this.selectedDocuments.splice(this.selectedDocuments.indexOf(document), 1);
    },
    openDocumentInAnotherWindow(document) {
      var addressLfdnr = 0;
      if (document.adrReference == null) addressLfdnr = this.selectedAddresses.find(address => address.wfCid == document.parentWfCid).additionalData.lfdnr;else addressLfdnr = document.adrReference.additionalData.lfdnr;
      const routeData = this.$router.resolve({
        name: 'crmAddressDocumentDetailed',
        params: {
          lfdnr: addressLfdnr,
          wf_cid: document.wfCid
        }
      });
      window.open(routeData.href, '_blank');
    },
    save() {
      this.selectedAddress = null;
      this.documentSearch = '';
      this.addressSearch = '';
      this.$emit('save', this.selectedAddresses, this.selectedDocuments);
    },
    isSelectedAddress: function (adr) {
      if (this.selectedAddress == adr) {
        return 'primaryColorRow';
      }
      return 'noColorRow';
    }
  },
  watch: {
    addressSearch(newValue) {
      if (newValue === '' || newValue === null) {
        this.selectableAddresses = [];
      } else {
        this.searchTrigger();
      }
    },
    selectableAddresses(newValue) {
      if (this.addressSearch === '' && newValue.length !== 0) this.selectableAddresses = [];
    },
    selectedAddress(newValue) {
      if (newValue == null) {
        this.selectableDocuments = [];
      }
    }
  },
  computed: {}
};