import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "900px",
      "persistent": ""
    },
    model: {
      value: _vm.taskAssignedGroup,
      callback: function ($$v) {
        _vm.taskAssignedGroup = $$v;
      },
      expression: "taskAssignedGroup"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "primary",
      "fixed": "",
      "top": "",
      "dark": "",
      "flat": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.taskAssignedGroup = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Benutzer in Gruppe")])], 1)], 1), _c(VCardText, [_c('h3', {
    staticClass: "mt-2 ml-2 mr-2 text-h6"
  }, [_vm._v(" Benutzer in Gruppe " + _vm._s(_vm.group.name) + " ")]), _c(VDataTable, {
    attrs: {
      "items": _vm.group.users,
      "headers": _vm.userHeaders
    }
  })], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    staticClass: "primary",
    on: {
      "click": function ($event) {
        _vm.taskAssignedGroup = false;
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };