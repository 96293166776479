export default {
  data: () => ({
    taskAssignedGroup: false,
    group: [],
    userHeaders: [{
      text: 'UserId',
      value: 'userId'
    }, {
      text: 'Benutzername',
      value: 'username'
    }, {
      text: 'E-Mail',
      value: 'email'
    }]
  }),
  methods: {
    open(group) {
      this.group = group;
      this.taskAssignedGroup = true;
    }
  },
  components: {}
};